import Papa from "papaparse";
import sortBy from "lodash.sortby";

export function fetchChapters(region, keyword) {
  const URL =
    process.env.NODE_ENV === "development"
      ? ""
      : "https://av-website-chapters-table.netlify.app";

  return fetch(`${URL}/google-sheets/${region}`)
    .then((response) => response.text())
    .then((csv) => {
      const { data: chapters } = Papa.parse(csv, { header: true });

      let filteredChapters = chapters
        .map((chapter) => {
          return {
            country: chapter["COUNTRY"],
            facebookLink: chapter["FACEBOOK LINK"],
            name: chapter["CHAPTER"],
          };
        })
        .filter((chapter) => chapter.country);

      if (keyword) {
        filteredChapters = filteredChapters.filter(({ country, name }) => {
          const keywordLowercased = keyword.toLowerCase();

          return (
            country.toLowerCase().includes(keywordLowercased) ||
            name.toLowerCase().includes(keywordLowercased)
          );
        });
      }

      return sortBy(filteredChapters, ["country", "name"]);
    });
}
