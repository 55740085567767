import React from "react";
import "./ChaptersTable.css";

export default function ChaptersTable({ chapters }) {
  return (
    <table className="chapters-table">
      <thead>
        <tr>
          <th>Country</th>
          <th>Chapter</th>
          <th>Facebook Group</th>
        </tr>
      </thead>
      <tbody>
        {chapters.map((chapter, i) => {
          return (
            <tr key={i}>
              <td>{chapter.country}</td>
              <td>{chapter.name}</td>
              <td>
                <a
                  href={chapter.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
