import "./App.css";
import React, { useEffect, useState } from "react";
import SearchForm from "./SearchForm";
import ChaptersTable from "./ChaptersTable";
import { fetchChapters } from "./store";

export default function App() {
  const [chapters, setChapters] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [region, setRegion] = useState("north-america.csv");

  useEffect(() => {
    fetchChapters(region, keyword).then((chapters) => {
      setChapters(chapters);
    });
  }, [region, keyword]);

  return (
    <div className="chapters-app">
      <SearchForm
        region={region}
        onSelectRegion={setRegion}
        keyword={keyword}
        onInputKeyword={setKeyword}
      />
      <ChaptersTable chapters={chapters} />
    </div>
  );
}
