import React from "react";
import "./SearchForm.css";

export default function SearchForm({
  region,
  onSelectRegion,
  keyword,
  onInputKeyword,
}) {
  const regions = [
    {
      label: "Africa",
      filename: "africa.csv",
    },
    {
      label: "Asia",
      filename: "asia.csv",
    },
    {
      label: "Central America",
      filename: "central-america.csv",
    },
    {
      label: "Europe",
      filename: "europe.csv",
    },
    {
      label: "Middle East",
      filename: "middle-east.csv",
    },
    {
      label: "North America",
      filename: "north-america.csv",
    },
    {
      label: "Oceania",
      filename: "oceania.csv",
    },
    {
      label: "South America",
      filename: "south-america.csv",
    },
  ];

  function handleRegionSelection(event) {
    onSelectRegion(event.target.value);
  }

  function handleKeywordInput(event) {
    onInputKeyword(event.target.value);
  }

  return (
    <form className="chapters-search-form">
      <div className="form-group">
        <label htmlFor="region-select">Select a region</label>
        <select
          id="region-select"
          value={region}
          onChange={handleRegionSelection}
        >
          {regions.map(({ label, filename }, i) => {
            return (
              <option key={filename} value={filename}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="search-input">Optionally filter by keyword</label>
        <input
          type="text"
          id="search-input"
          placeholder="Search..."
          onChange={handleKeywordInput}
          value={keyword}
        />
      </div>
    </form>
  );
}
